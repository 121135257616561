// palette for Vegas Days Casino
$bg-color: #111356;
$text-color: #fff;
$primary: #0447fe;
$success: #98FF05;
$info: #05CBFF;
$danger: #FF3505;


$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;

#rivalHeaderNavAndLogoSection {
  img {
    margin-top: -5px;
    max-height: 50px;
    width: auto !important;
  }
}


//menu in primary color
.sticky-sidebar {
  .nav-link:hover, .active-link {
    color: $primary !important;
    .variable-svg-icon {
      background-color: $primary !important;
    }
  }
}


